// extracted by mini-css-extract-plugin
export var black = "Callout-module--black--1hIA9";
export var callout = "Callout-module--callout--1EDr3";
export var calloutBody = "Callout-module--calloutBody--4DWTQ";
export var calloutButtons = "Callout-module--calloutButtons--39dN0";
export var calloutButtonsOutline = "Callout-module--calloutButtonsOutline--30XAU";
export var calloutHeading = "Callout-module--calloutHeading--17Rwe";
export var ctas = "Callout-module--ctas--2u7tA";
export var ctasButton = "Callout-module--ctasButton--osZAf";
export var ctasLink = "Callout-module--ctasLink--3m4MZ";
export var showIn = "Callout-module--show-in--11lFY";
export var smallTitle = "Callout-module--smallTitle--CjjA4";
export var subtitle = "Callout-module--subtitle--28bXP";
export var title = "Callout-module--title--3rYk4";