import React from "react"

import Layout from "@src/components/Layout"
import CommunityCallout from "@src/components/CommunityCallout"
import Callout from "@src/components/Callout"
import getAppUrl from "@src/utils/getAppUrl"

import SecurityIllustration from "@src/icons/v2/security-splash.svg"
import ServerlessArch from "@src/icons/v2/serverless-arch.svg"
import RedeployGraph from "@src/icons/screenshots/redeploy-graph.png"
import OG from "@src/icons/og/OG_Serverless.jpeg"

import Splash from "./components/Splash"
import Row from "../components/Row"
import ImageRow from "./components/ImageRow"

const IndexPage = ({ location }) => {
  const appUrl = getAppUrl.hook(location)
  return (
    <Layout
      title="Keep your backend alive on the platform"
      description="Low backend costs much higher scalability."
      url="https://canonic.dev/features/sdk"
      location={location}
      ogImage={OG}
    >
      <Splash
        illustrationComponent={<ServerlessArch />}
        title="Serverless Architecture"
        subtitle="Keep your backend alive on the platform"
      />
      <Row>
        <ImageRow
          title="Instant Deployments, no downtime required"
          subtitle="Any changes made to the API are deployed instantly without requiring any downtime making sure your users get the latest update with the least hassle."
          screenshot={RedeployGraph}
        />
      </Row>
      <Row>
        <ImageRow
          title="More secure, better performance"
          subtitle="Tight security policies regarding serverless architecture leads to a more secure backend less prone to hacking and data theft."
          icon={SecurityIllustration}
          reverse
        />
      </Row>

      <Callout location={location} title="serverlessArchitecture" />
      <CommunityCallout />
    </Layout>
  )
}

export default IndexPage
