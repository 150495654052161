import React from "react"
import Link from "gatsby-plugin-transition-link"
import getAppUrl from "@src/utils/getAppUrl"

import { TESTS } from "@src/data/test"
import useTest from "@src/hooks/useTest"
import * as styles from "./Callout.module.scss"
import Row from "../../layouts/V2/components/Row"
import DotBG from "../../layouts/V2/components/DotBG"
import useMobile from "../../layouts/V2/utils/isMobile"

const Callout = ({ className, location, title, black }) => {
  const isMobile = useMobile()
  const appUrl = getAppUrl.hook(location)
  const { weightIndex, trackTest } = useTest(TESTS.BUILD_WITH_US)
  const trackClick = () => {
    if (!title) return
    trackTest(
      TESTS.BUILD_WITH_US.tests[weightIndex].name,
      TESTS.BUILD_WITH_US.key,
      `${title}_Last_Section`
    )
  }
  return (
    <Row
      className={`${styles.callout} ${black ? styles.black : ""} ${className}`}
    >
      {!black && (
        <DotBG
          height={isMobile ? "100%" : "100%"}
          offset={isMobile ? "0%" : "0%"}
          reverse
        />
      )}
      <section className={styles.calloutBody}>
        <h2 className={styles.title}>Enough said, let's start building</h2>
        <p className={styles.subtitle}>
          Start using canonic's fullstack solution to build internal tools for
          free
        </p>
        <div className={styles.ctas}>
          <a href={appUrl} target="_blank" rel="noopener noreferrer">
            <button className={styles.ctasButton}>Start for free</button>
          </a>
          <Link
            className={styles.ctasLink}
            to={"/get-a-demo"}
            entry={{ length: 0.3 }}
            exit={{ length: 0.3 }}
          >
            Get a live demo
          </Link>
        </div>
      </section>
    </Row>
  )
}
export default Callout
